

@font-face {
    font-family: 'Lexend Giga';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/lexendgiga/v25/PlIuFl67Mah5Y8yMHE7lkUZPlTBo4MWFfNRC2Li048Apy-K5YGS1rOE.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
  }
  /* latin-ext */
  @font-face {
    font-family: 'Lexend Giga';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/lexendgiga/v25/PlIuFl67Mah5Y8yMHE7lkUZPlTBo4MWFfNRC2Li04sApy-K5YGS1rOE.woff2) format('woff2');
    unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
    font-family: 'Lexend Giga';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url(https://fonts.gstatic.com/s/lexendgiga/v25/PlIuFl67Mah5Y8yMHE7lkUZPlTBo4MWFfNRC2Li07MApy-K5YGS1.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  

  .mainAuth{
    height: 100vh;
    width: 100%;
    display: flex;
    
}

  


.AuthLeft{
    height: 100vh;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
}


.AuthRight{
    height: 100vh;
    width: 40%;
    display: flex;
    align-items: left;
    justify-content: center;
    flex-direction: column;
    padding-left: 5%;
    padding-right: 5%;
}


.signuptextCont{
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
}

.signuptextCont h6{
    margin-top: 8px;
    margin-bottom: 0;
    padding: 0;
}

.signuptext{
    text-align: center;
    font-size: 13px;
    margin-top: 10px;
    color: #000000;
    text-decoration: none;
    margin-left: 10px;
    margin-right: 10px;
}

.signuptext2{
  text-align: left;
  font-size: 13px;
  margin-top: 10px;
  color: #000000;
  text-decoration: none;
  margin-left: 0px;
  margin-right: 10px;
}

.signuptext:hover{
    color: #0b66dc;
}


.btnLogin{ 
    margin-top: 5px;
    background-color: #FF385C;
    border: #FF385C 1px solid;
    padding-top: 10px;
    width: 100%;
    padding-bottom: 10px;
}

.btnLogin:hover{
    background-color:#f5234a;
    border: 1px solid #f5234a;
}

.saveChgsBtn{
  margin-top: 30px;
  background-color: royalblue;
  border: royalblue 1px solid;
  padding-top: 10px;
  padding-bottom: 10px;
}

.saveChgsBtn:hover{
  background-color:royalblue;
  border: 1px solid royalblue;
}

.authleftCont{
    height: 96%;
    width: 96%;
    background-color: white;
    border-radius: 0px 20px 0px 20px;
    overflow: hidden;
    position: relative;
}


.logoAuth{
    height: 10vh;
    width: auto;
    margin-bottom: 50px;
}


.logoDash{
    height: 10vh;
    width: max-content;
    margin-bottom: 10px;
}


.authleftCont video{
    height: 100%;
    width: 100%;
    object-fit: cover;
}



.authLeftOpacity{
    height: 100%;
    width: 100%;
    background-color: black;
    position: absolute;
    top: 0;
    opacity: 0.2;
}


/* h3{

    font-family: Lexend Giga;

} */

.countryIcon{
    height: 4vh;  
}


.accSetMain{
    display: flex;
    height: 100vh;
    width: 100%;
}

.accSet1{
    width: 60%;
    padding: 40px; 
    overflow: scroll; 
    
}


.accSet1 h2{
    margin-bottom: 20px;
    font-size: 25px;
    font-weight: bold;
    
}

.accSet2{
    width: 40%;
    background-color: rebeccapurple;
    background-image: url(../Images/demothree.jpg);
    background-size: cover;
    position: relative;
    border-radius: 30px 0px 0px 30px;
    overflow: hidden; 
    
}

.theOpacity{
  position: absolute;
  background-color: black;
  height: 100%;
  width: 100%;
  opacity: 0.5;


}


.theOpacitySetup{
  position: absolute;
  bottom: 0;
  z-index: 11;
  width: 100%;
  padding: 40px;
  color: white; 
}

.theOpacitySetup h2{
  font-size: 25px;
  font-weight: bolder;
  text-align: center;  
  color: white;
}

.theOpacitySetup p{
  font-size: 13px; 
  text-align: center;
  font-weight: 500;
}

.accSetupCover{
    width: 100%;
    height: 30vh;
    background-color: red;
    border-radius: 5px;
    overflow: hidden;
}

.accSetupCover2{
  width: 100%; 
  height: 25vh;
  background-color: red;
  border-radius: 5px;
  overflow: hidden;
}

.accSetupProfile{
    height: 200px;
    width: 200px;
    background-color: royalblue;
    border-radius: 50%; 
    position: absolute;
    top: 15vh;
    left: 5%;
    border: 5px solid white;
    overflow: hidden; 
}

.accSetupProfile2{
  height: 150px;
  width: 150px;
  background-color: royalblue;
  border-radius: 50%; 
  position: absolute;
  top: 13vh;
  left: 37%;
  border: 5px solid white;
  overflow: hidden; 
}

.accSetupProfCont{
    position: relative;
}

.accSetupTexts{
    position: absolute;
    top: 31vh;
    left: 250px; 
}

.accSetupTexts p{
    padding: 0;
    margin: 0;
}


.accSetupTexts h3{ 
    padding: 0;
    margin: 0;
}  

 
.accSetupTexts2{
  position: absolute;
  top: 35vh;
  text-align: center;
  width: 100%;
}

.accSetupTexts2 p{
  padding: 0;
  margin: 0;
  font-size: 14px; 
}


.accSetupTexts2 h3{ 
  padding: 0;
  margin: 0;
}

.profBottomCover2{
    width: 100%;
    position: relative; 
    margin-top: 20vh; 
    padding-right: 20px;
    padding-bottom: 30px;
  }

.startAcallContentBotton{
  width: 100%;
  position: relative; 
  margin-top: 20vh; 
  padding-right: 20px;
  padding-left: 20px; 
}

.alertPr2{
  font-size: 13px;
}

.cuzAlert{
  padding-bottom: 0px !important;
}

.startAcallContentButton{

  margin-bottom: 20px;
  background-color: #f5234a;
  padding: 10px 20px;
  border: 1px solid #f5234a;
  color: white;
  width: 90%;
  margin-left: 5%;
  border-radius: 5px;
}

.startAcallContentButton:hover{
  color: #f5234a;
  border: 1px #f5234a solid;
  background-color: white;
}

.pickupDivsc{
    background-color: rgba(187, 184, 184, 0.749);
    padding: 5px;
    font-size: 14px;
    cursor: pointer;
}

.alertPr{
    font-size: 13px;
    padding: 0;
    margin: 0; 
}


.accSetupCover img{
    object-fit: cover;
    height: 100%;
    width: 100%; 
    
}

.accSetupCover2 img{
  object-fit: cover;
  height: 100%;
  width: 100%; 
  
}

.accSetupProfile img{
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.accSetupProfile2 img{
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.EditProfileBody button{
    background-color: royalblue;
    color: white;
    border: 1px royalblue solid;
    padding-left: 20px;
    padding-right: 20px;

}

.EditProfileBody{
  padding-bottom: 100px;  
}

/*  */

  .service-container {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    margin-top: 50px;
    gap: 20px; /* Add space between the service boxes */
    flex-wrap: wrap; /* This allows wrapping if the screen is small */
    margin-bottom: 30px;
  }
  
  .service-box {
    background-color: #f0f0f0;
    border: 2px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    width: 150px;
    cursor: pointer;
    transition: all 0.3s ease;
  
  }
  
  .service-box p {
    margin-top: 10px;
    font-size: 18px;
    font-weight: bold;
  }
  
  .service-box:hover {
    background-color: #ececec;
    border-color: #888;
  }
  
  .selected {
    background-color: #4CAF50;
    color: white;
    border-color: #4CAF50;
  }
  
  .availability-container {
    margin-top: 20px;
    width: 100%;
  }
  
  .availability-box {
    margin: 20px 0;
    padding: 20px;
    border: 2px solid #ccc;
    border-radius: 10px;
  }
  
  h4 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .days-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
  }
  
  .day-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .day {
    padding: 10px;
    background-color: #f9f9f9;
    border: 2px solid #ccc;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s, border-color 0.3s;
    border-radius: 5px;
  }
  
  .day:hover {
    background-color: #ddd;
  }
  
  .day-selected {
    background-color: #4CAF50;
    color: white;
    border-color: #4CAF50;
  }
  
  .time-inputs {
    display: flex;
    gap: 10px;
    margin-top: 10px;
  }
  
  label {
    font-size: 14px;
    font-weight: bold;
  }
  
  input[type="time"] {
    padding: 5px;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }

/*  */
.setupPage-container {
    padding: 20px;
    background-color: #f7f9fc;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .setupPage-header {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }
  
  .serviceSelection-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
    width: 100%;
    max-width: 800px;
  }
  
  .serviceCard {
    background: white;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 15px;
    text-align: center;
    transition: transform 0.2s;
  }
  
  .serviceCard:hover {
    transform: scale(1.05);
  }
  
  .serviceIcon {
    font-size: 30px;
    margin-bottom: 10px;
    color: #4CAF50; /* Customizable color */
  }
  
  .amountInput-field {
    margin-top: 20px;
    width: 100%;
    max-width: 300px;
  }
  
  .submitButton {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submitButton:hover {
    background-color: #45a049;
  }
  

/* myEarnings */


.myEarnings{
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 40px;
}

.myEarningsTop{
  display: flex;
  justify-content: space-between;
 
}

.earningsLeft{
  height: 400px;
  width: 70%;
  background-color: royalblue;
  border-radius: 20px 20px 0px 0px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
}


.earningsLeft h1{
  color: white;
}

.earningsLeft img{
  height: 100%;
  position: absolute;
  top: 0;right: 0;
  width: 100%;
}

.earningsLeftDiv{
  position: absolute;
}

.earningsRight{
  width: 28%;
  background-color: #4CAF50;
  border-radius: 20px 20px 0px 0px;
  height: 400px;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}


.dashDateCont{
  border-radius: 50%;
  color: #6f6f6f;
  height: 170px;
  width: 170px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;

}

.earningsRight button{
  background-color: #000000;
  border: 1px solid #fff;
  color: white;
  padding: 5px 20px;
  border-radius: 20px;
}
.payoutviewlink{
  color: white;
  text-decoration: none;
  margin-top: 5px;
}

.earningsRight p{
  font-size: 18px; 
  font-weight:500;
}