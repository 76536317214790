/* For responsiveness */
@media screen and (max-width: 768px) {

    .startAcallContentDiv{
      width: 80%;
    }

    .accSetupCover2{
      width: 100%; 
      height: 20vh !important;
    }

    .accSetupProfile2{
      height: 115px !important;
      width: 115px !important;
      top: 10vh !important; 
      left: 30% !important; 
    }

    .accSetupTexts2 h3{
      padding: 0;
      margin: 0;
      font-size: 16px !important;
      font-weight: 700 !important; 
    }

    .accSetupTexts2{
      top: 28vh !important;
      text-align: center;
    }

    .accSetupTexts2 p{
      font-size: 12px;
    }

    .alertPr2{
      font-size: 12px;
    }

    .cusCallLabel{
      font-size: 12px;
    }

    .startAcallContentButton{
      font-size: 12px;
    }

    .startAcallContentBotton{
      margin-top: 15vh !important; 
      padding-right: 10px !important;
      padding-left: 10px !important; 
    }
  
    .signUpContainer {
      flex-direction: column;
    }
    
    .signUpContainer1 {
      width: 100%;
      height: 100%;
    }

    .signuptext{
      text-decoration: none;
      color: #222222;
      padding: 0px;
      font-weight: 500; 
      margin-right: 5px; 
      margin-bottom: 5px;
      font-size: 13px;
    }

    .logo{
      height: 40px; 
    }

    .searchResults{
      width: 100%;
    }

    .accountSection {
      padding-left: 5%;
      padding-right: 5%;
      display: block;
      flex-wrap: wrap;
      justify-content: center; /* Centers the items horizontally */
    } 

    .servicePoviderListing{
      width: 100% !important; 
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 0px;
      margin-bottom: 20px;
    }

    .heardEnough{
      height: 40vh;
      width: 100%;
      background-color: #f0f2f5;
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      /* padding-left: 5%;
      padding-right: 5%;  */
      padding-top: 30px;
      padding-bottom: 30px; 
    }


    .bottomFooter{
      height: auto;
      width: 100%;
      background-color: #222222;
      background-image: url(./Images/BG-3.a47b2fbe.webp);
      /* padding-left: 10%;  
      padding-right: 10%;  */
      color: #fff;
      text-align: left;
    
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding: 50px 5%;
    }

    .footerColumn {
      width: 100%;
    }
    
    .footerColumn h2 {
      font-size: 20px;
      margin-bottom: 20px;
      color: #fff;
      font-weight: 700;
      margin-top: 30px;
    }

    .heardEnough h6{
      width:max-content;
      margin: 0;
      padding: 0;
    }

    .profileHeader{
      width: 100%;
      height: 25vh; 
      margin-top: 0vh;
    }

    .viewProfPicCont{
      height: 130px;
      width: 130px;
      border: 5px solid #fff;
      border-radius: 50%;
      position: absolute;
      top: 15vh;
      left: 5%;
      overflow: hidden;
    }

    .profNameCont{
      position: absolute;
      left: 150px;  
      width: max-content;
      padding-top: 5px;
      flex-direction: column;
      padding-left: 0px;
      margin-left: 0px;
      align-items: start;
      justify-content:start;
    
    }

    .profNameCont h3{
      padding: 0;
      margin: 0;
      font-size: 16px;
      font-weight: 700; 
    }
    
    
    .profNameCont p{
      padding: 0;
      margin: 0;
      font-size: 11px;  
    } 

    .profBottomCover{
      width: 100%;
      position: relative; 
      padding-top: 80px; 
      display: flex;
      flex-direction: column;
      padding-left: 5%;
      padding-right: 5%; 
      padding-bottom: 50px;
    }

    .profBottomOne{
      width: 100%;
      background-color: #fff;
      height: max-content;
      border-radius: 10px;
      box-shadow: 0 .5rem 1.25rem rgba(115, 82, 199, .18);
      padding: 20px; 
      position: static;
      top: 10vh; 
    }

    /*  */

    .myEarningsTop{
      display: block !important;
      justify-content: space-between;

    }

    .earningsLeft{
      height: 200px !important;
      width: 100% !important;
      background-color: royalblue;
      border-radius: 20px 20px 0px 0px;
      padding: 25px;
      display: flex;
      flex-direction: column;
      position: relative;
      justify-content: center;
      margin-bottom: 20px !important;
    }


    .earningsRight{
      width: 100% !important;
      background-color: #4CAF50;
      border-radius: 20px 20px 0px 0px;
      height: 400px;
      align-items: center;
      display: flex;
      justify-content: center;
      flex-direction: column;
    }

    .earningsLeft h1{
      color: white;
      font-size: 20px;
    }

    .earningsLeft h5{
      font-size: 13px;
    }

    
    .profBottomTwo2{
      width: 100%; 
      height: 100%;
      padding-left: 1%;
      padding-right: 1%; 
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      overflow-x: auto; /* Allows horizontal scrolling if there are many items */
      scrollbar-width: none; /* For Firefox */
      margin-top: 20px; 
    }
    

    .servicePoviderListing2{
      width: 100%;
      margin-bottom: 10px;
      margin-left: 0px;
      margin-right: 0px; 
      position: relative;
      cursor: pointer;
      margin-top: 10px;
    }


    .modal-content{
      width: 100%;
      /* background-color: #222222;  */
    }

    .modal-dialog{
      margin-top: 20px; 
      position: sticky;
      top: 20px;
    }


    .chatButton{
      background-color: #FF385C;
      color: white;
      height: 25px; 
      width: 25px;
      border-radius: 50%;
      display: flex;
      align-items: center; 
      justify-content: center;
      cursor: pointer;
      z-index: 99;
      font-size: 11px; 
    }

    .userProfileContactCentre div{
      margin-left: 0px;
      margin-right: 5px;
    } 

    .userProfileContactCentre{
      padding-top: 5px;
    }

    .profBottomTwo2{
      width: 100%;   
      height: 100%;
      padding-left: 1%;
      padding-right: 1%; 
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      overflow-x: auto; /* Allows horizontal scrolling if there are many items */
      scrollbar-width: none; /* For Firefox */
    }

    .profBottomTwo{
      width: 100%;  
      height: 100%;
      padding-left: 1%;
      padding-right: 1%; 
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      overflow: scroll;
      padding-top: 50px;
      position: relative; 
    }

    .topUploadProfilePage{
      position:static;
      right: 0; 
      margin-bottom: 20px;
    }

    .topUploadProfilePage button{
      margin-right: 10px;
      margin-left: 0px;  
    
    }

    .bottomPageEdit{
      padding-right: 5% !important;
      padding-top: 0px; 
      padding-left: 5%; 
      margin-right: 5%;
    }

    .signUpContainer2{
      display: none;
    }

    .signUpContainer {
      height: 100vh;
      width: 100%;
      display: flex;
      flex-direction: column;
      background-color: #f4f5f7;
      font-family: 'Poppins', sans-serif;
      color: #333;
    }

    .signUpContainer1 {
      padding: 30px;
    }
  
    .signUpContainer1 h1 {
      font-size: 2rem;
    }
  
    .signUpContainer1 h3 {
      font-size: 1.2rem;
    }
  
    .signUpContainer1 h2 {
      font-size: 2rem;
    }
  
    .signUpContainer1 p {
      font-size: 1rem;
    }
  
    .incomeSlider {
      margin-bottom: 20px;
    }
  
    .ctaButton {
      padding: 10px 25px;
      font-size: 1rem;
    }
  
    .logoSignUp {
      height: 35px;
      margin-bottom: 50px;

    }
  
    .signUpContainer2 {
      display: none; /* Hides the image for mobile view */
    }

    .mainAuth{
      height: 100vh;
      width: 100%;
      display: flex;
      flex-direction: column;
    }

    .AuthRight{
      height: 100vh;
      width: 100% !important; 
      display: flex;
      align-items: left;
      justify-content: center;
      flex-direction: column;
      padding-left: 5%;
      padding-right: 5%;
    }

    .AuthLeft{
      display: none !important;
    }

    .logoAuth{
      height: 35px !important;
      margin-bottom: 50px !important;
    }

    .accSetMain{
      display: flex;
      height: 100vh;
      width: 100%;
      flex-direction: column;
    }
    .accSet1{
      width: 100% !important; 
      padding: 20px !important; 
      overflow: scroll; 
      
    }

    .accSetupCover{
      width: 100%;
      height: 25vh !important; 
      background-color: red;
      border-radius: 5px;
      overflow: hidden;
    }

    .accSetupProfile{
      height: 130px !important;
      width: 130px !important;
   
    }

    .accSetupTexts{
      position: absolute;
      top: 25vh !important;
      left: 150px !important;
      padding-top: 5px !important;
    }

    .accSetupTexts h3{
      padding: 0;
      margin: 0;
      font-size: 16px;
      font-weight: 700; 
    }
    
    
    .accSetupTexts p{
      padding: 0;
      margin: 0;
      font-size: 11px;  
    } 

    .profBottomCover2{
      width: 95% !important;
      position: relative; 
      margin-top: 15vh !important; 
      padding-right: 0px !important;
      padding-bottom: 30px; 
    }

    .querySection{
      display: none;
    }

    .searchBoxMobile{
      display: flex; 
    }

    .aboutTopBarCont p{
      margin-left: 5%;
      margin-right: 5%;
      font-size: 13px;

    }

    img.img-fluid {
      max-width: 80%;
      height: auto; 
      margin-left: 10%;
      margin-right: 10%;
    }

    .about-section, .mission-section, .clients-section, .therapists-section, .why-us-section, .vision-section {
      padding: 0px;
      background-color: #f9f9f9;
    }
    

    .about-section{
      padding-left: 5%;
      padding-right: 5%; 
    }

    .about-section h2{
      margin-top: 20px;
    }

    .about-section p{
      font-size: 13px;
    }

    .mission-section{
      padding-left: 5%;
      padding-right: 5%; 
      margin-top: 20px;
    }
    .mission-section p{
      font-size: 13px;
    }

    .clients-section{
      padding-left: 5%;
      padding-right: 5%; 
      margin-top: 20px;
    }

    .clients-section p{
      font-size: 13px;
    }

    .feature-item{
      font-size: 10px;
    }

    .feature-item h3{
      font-size: 14px;
    }

    .therapists-section{
      padding-left: 5%;
      padding-right: 5%; 
      margin-top: 20px;
    }
    .therapists-section p{
      font-size: 13px;
    }

    .why-us-section{
      padding-left: 5%;
      padding-right: 5%; 
      margin-top: 20px;
    } 

    .why-us-section p{
      font-size: 13px; 
    }


    .vision-section{
      padding-left: 5%;
      padding-right: 5%; 
      margin-top: 20px;  
    }

    .vision-section p{
      font-size: 13px; 
    }

    .pricing-packages {
      display: block;
      justify-content: space-between;
      gap: 20px;
    }

    .pricing-package {
      width: 100%; 
    }


    .verifiedMobileSpan{
      display: block;
    }

    .verifiedMobileSpan2{
      display: block;
      font-size: 12px;
      background-color: royalblue;
      padding: 4px 20px;
      color: white;
      border-radius: 20px;
      margin-left: 10px;
      z-index: 4;
      cursor: pointer;
      position: relative;  
    }

    .getverifiedSpan{
      padding-left: 10px;
      padding-right: 10px;
      font-size: 12px;
    }

    .getverifiedSpan33{
      display: none;
    }
  }