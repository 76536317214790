@import url('https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&display=swap');

body {
  font-family: 'Figtree', sans-serif;
}

/* NAVBAR  */

.logo{
  height: 7vh;
}

.navbar{
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2vh;
  display: flex !important; 
}

.theNavbar{
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 2vh;
  display: flex !important; 
}

.signuptext{
  text-decoration: none;
  color: #222222;
  padding: 10px 15px;
  font-weight: 500; 
  margin-right: 20px;
}

.signuptext:hover{
  background-color: #F2F2F2;
  border-radius: 20px;
}

.profileCont{
  height: 35px;
  width: 35px;  
  border-radius: 50%;   
  margin-left: 15px;
}

.accountCont{
  display: flex;
  border: 1px solid #B0B0B0;
  align-items: center;
  justify-content: center;
  cursor: pointer; 
  border-radius: 30px;
  padding: 5px 10px;
}

.navbarTwo{
  display: flex;
  align-items: center;
}

.barsIcon{
  color: #222222;  
}

.profileCont img{
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 50%; 
}


/* SEARCH BOX */

.querySection{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5vh;
  padding-bottom: 5vh;
  box-shadow: rgb(0 0 0 / 8%) 0 1px 0;
  position: relative;

}

.searchBox{
  border: 1px solid #dddddd; 
  width: 60%;
  border-radius: 40px;
  overflow: hidden;
  display: flex;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
}


@media screen and (max-width: 1025px) and (min-width: 769px)  {

  .startAcallContentDiv{
    width: 80%;
  }
  .searchBox{
    width: 90%;
  }

  .accSet2{
    display: none !important;
  }

  .accSet1{
    width: 100% !important;
  }

  .accSetupCover {
    width: 100%;
    height: 25vh !important; 
  }

  .accSetupTexts {
    position: absolute;
    top: 26vh !important;
    left: 250px;
  }

  

  .profBottomCover2 {
    margin-top: 10vh !important; 
  }

  .servicePoviderListing{
    width: 300px !important;
    margin: 10px; 
  }

  .accountSection {
    padding-left: 0% !important;
    padding-right: 0% !important;
  } 

  .profileHeader{
    width: 100%;
    height: 25vh !important; 
    margin-top: 0vh !important;
  }

  .viewProfPicCont{
    height: 130px;
    width: 130px;
    border: 5px solid #fff;
    border-radius: 50%;
    position: absolute;
    top: 15vh !important;
    left: 5%;
    overflow: hidden;
  }
  .profNameCont {
    position: absolute !important; 
    left: 310px !important; 
    width: max-content !important;
    padding-top: 7px !important; 
    flex-direction: column !important;
    padding-left: 0px !important;
    margin-left: 0px !important;
    align-items: start !important; 
    justify-content: start !important;
  }
  
  .profNameCont h3 {
    padding: 0 !important;
    margin: 0 !important;
    font-size: 16px !important;
    font-weight: 700 !important;
  }
  
  .profNameCont p {
    padding: 0 !important;
    margin: 0 !important;
    font-size: 11px !important;
  }
  
  .profBottomCover {
    width: 100% !important;
    position: relative !important;
    padding-top: 180px !important;
    display: flex !important;
    flex-direction: column !important;
    padding-left: 5% !important;
    padding-right: 5% !important; 
    padding-bottom: 50px !important;
  }
  
  .profBottomOne {
    width: 100% !important;
    background-color: #fff !important;
    height: max-content !important;
    border-radius: 10px !important;
    box-shadow: 0 0.5rem 1.25rem rgba(115, 82, 199, 0.18) !important;
    padding: 20px !important;
    position: static !important;
    top: 10vh !important;
  }
  
  .profBottomTwo2 {
    width: 100% !important;
    height: 100% !important;
    padding-left: 1% !important;
    padding-right: 1% !important;
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: flex-start !important;
    overflow-x: auto !important; /* Allows horizontal scrolling if there are many items */
    scrollbar-width: none !important; /* For Firefox */
    margin-top: 20px !important;
  }
  
  .servicePoviderListing2 {
    width: 100% !important;
    margin-bottom: 10px !important;
    margin-left: 0px !important;
    margin-right: 0px !important;
    position: relative !important;
    cursor: pointer !important;
    margin-top: 10px !important;
  }
  
  .modal-content {
    width: 100% !important;
    /* background-color: #222222 !important; */
  }
  
  .modal-dialog {
    margin-top: 20px !important;
    position: sticky !important;
    top: 20px !important;
  }
  
  .chatButton {
    background-color: #FF385C !important;
    color: white !important;
    height: 25px !important;
    width: 25px !important;
    border-radius: 50% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer !important;
    z-index: 99 !important;
    font-size: 11px !important;
  }
  
  .userProfileContactCentre div {
    margin-left: 0px !important;
    margin-right: 5px !important;
  }
  
  .profBottomTwo {
    width: 100% !important;
    height: 100% !important;
    padding-left: 1% !important;
    padding-right: 1% !important;
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: flex-start !important;
    overflow: scroll !important;
    padding-top: 50px !important;
    position: relative !important;
  }
  
  .topUploadProfilePage {
    position: static !important;
    right: 0 !important;
    margin-bottom: 20px !important;
  }
  
  .topUploadProfilePage button {
    margin-right: 10px !important;
    margin-left: 0px !important;
  }
  
  .bottomPageEdit {
    padding-right: 5% !important;
    padding-top: 0px !important;
    padding-left: 5% !important;

  }
  

} 


@media screen and (max-width: 1250px) and (min-width: 1050px) {
  .searchBox {
    width: 70%;
  }
  .startAcallContentDiv{
    width: 80%;
  }
}


.searchBox span{
  height: 100%;
  font-size: 40px;
  font-weight: 10; 
  color: #B0B0B0;
}
.locationSearch{
  height: 70px;
  width: 35%;
  padding-left: 30px; 
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-radius: 40px;
  /* position: relative; */
}

.pickupDivsc2{
  position: absolute;
  z-index: 99;
  height: 200px;
  bottom: 4px;
  top: 75px;
  background-color: #fff;
  box-shadow: 0 3px 12px 0 rgb(0 0 0 / 0.15); 
  border-radius: 5px; 
}

.pickupDivsc2 div{
  margin: 10px;  

}

.pickupDivsc22{
  position: absolute;
  z-index: 99;
  height: 200px;
  bottom: 4px;
  /* width: 90%; */
  top: 150px;
  background-color: #fff;
  box-shadow: 0 3px 12px 0 rgb(0 0 0 / 0.15); 
  border-radius: 5px; 
}

.pickupDivsc22 div{
  margin: 10px;  
}


.genderModalSearch{
  position: absolute;
  z-index: 99;
  height: max-content;
  bottom: 4px;
  top: 75px;
  background-color: #fff;
  box-shadow: 0 3px 12px 0 rgb(0 0 0 / 0.15); 
  border-radius: 5px; 
  padding: 20px;
}


.genderModalSearch p{
  margin: 0;
  padding: 0; 
}

.locationSearch:hover{
  background-color: #F2F2F2;
  cursor: pointer;
 
}

/* Hide the span11 when hovering on locationSearch */
.locationSearch:hover + .span11 {
  visibility: hidden;
}

/* Hide the span13 when hovering on genderSearch */
.genderSearch:hover + .span13 {
  visibility: hidden;
}

/* Hide the span14 when hovering on ageSearch */
.ageSearch:hover + .span14 {
  visibility: hidden;
}

/* For the last div (priceRangeSearch), we hide the span to its left */
.priceRangeSearch:hover .span14 {
  visibility: hidden;
}


.locationSearch label{
  font-size: 13px;
  font-weight: 500; 
  margin-left: 1px;
}
.locationSearch input{
  font-size: 13px;
  outline: none;
  border: none;
  background-color: transparent; 
}

.genderSearch{
  width: 20%;  
  border-radius: 40px;
  display: flex;
  padding-left: 10px; 
  flex-direction: column;
  justify-content: center;
  
 

}

.genderSearch2{
  width: 100%;  
  display: flex;
  padding-left: 10px; 
  flex-direction: column;
  justify-content: center;
  border: 1px solid gray;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 5px;
  border-radius: 5px;

}


.mobileSearchButton{
  background-color: #FF385C;
  width: 100%;
  padding: 10px;
  color: white;
  border: none;
}

.genderSearch:hover{
  background-color: #F2F2F2;
  cursor: pointer;
}

.ageSearch:hover{
  background-color: #F2F2F2;
  cursor: pointer;
}

.priceRangeSearch:hover{
  background-color: #F2F2F2;
  cursor: pointer;
}


.ageSearch{
  width: 15%;  
  border-radius: 40px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

}

.ageSearch2{
  width: 100%;  
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 1px solid gray;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 5px;
  border-radius: 5px;

}

.priceRangeSearch{
  width: 30%; 
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 40px;
  padding-left: 10px;
}

.searchSubmitBtn{
  background-color: #FF385C;
  height: 55px;
  width: 55px !important; 
  border-radius: 50%;
  border:none;  
  color: white; 
  margin-right: 10px;
  z-index: 99;

  /* To make it fixed and not pushable */
  position: relative;
  display: inline-block;  /* Ensures the button respects its own size */
  flex-shrink: 0;         /* Prevents it from shrinking inside flex containers */ 
}

.searchTitle{
  font-size: 13px;
  font-weight: 500; 
  padding: 0;
  margin: 0;
}

.searchP{
  font-size: 13px;
  padding: 0;
  margin: 0;
}

/* accountSection */

.accountSection {
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: left; /* Centers the items horizontally */
} 

.servicePoviderListing{
  width: 300px;
  margin: 10px;
}

.serviceProvImageCont{
  width: 100%;
  height: 300px;
  border-radius: 10px; 
  overflow: hidden;
}

.serviceProvImageCont img{
  object-fit: cover;
  height: 100%;
  width: 100%; 
  object-position: 50% 20%; /* Horizontally center (50%) and vertically position at 20% from the top */
}

.servicePoviderListing P{
  color: #6A6A6A;
  margin: 0;
  padding: 0;
  font-size: 13px;
}

.servicePoviderListing h6{
  margin-bottom: 0;
  padding: 0;
  font-size: 14px;
  margin-top: 5px;
  color: #222222;
}


.serviceProvLink{
  text-decoration: none;
}




/* servicesSection */


.servicesSection{
  width: 100%;
  /* border-top: 0.5px solid #B0B0B0; */
  border-bottom: none;
  border-left: none;
  border-right: none;
  margin-bottom: 0vh;
  padding-left: 5%;
  padding-right: 5%;
  /* box-shadow: rgb(0 0 0 / 8%) 0 1px 0; */
}

.massage-types-container {
  display: flex; /* Horizontal layout */
  justify-content: space-around;
  flex-wrap: nowrap; /* Keeps items in one row */
  overflow-x: auto; /* Allows horizontal scrolling if there are many items */
  padding-right: 10px;
  margin-top: 10px;
  padding-top: 10px;
  cursor: pointer;
  scrollbar-width: none; /* For Firefox */
}

.massage-type.selected {
  text-decoration: underline #222222; /* Underline the selected service */
  font-weight: bold; /* Optional: make the text bold for better visibility */
  background-color: transparent;
} 

.massage-types-container::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Edge */
}
 
.massage-type { 
  text-align: center;
  /* margin-right: 5px; */
  min-width: 100px; 
  text-underline-offset: 10px; 
  /* background-color: #222222; */
}

.icon {
  font-size: 25px; /* Adjust icon size */
  color: #6a6a6a; /* Customize icon color */
}
 
.massage-type p {
  margin-top: 10px;
  font-size: 12px;
  color: #6a6a6a; /* Customize text color */
}


/* profileHeader */

.profPage{
  background-color: #F0F2F5;
}

.profileHeader{
  width: 100%;
  height: 50vh;
  margin-top: 0vh;
}


.profileHeader img{
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.viewProfPicCont{
  height: 250px;
  width: 250px;
  border: 5px solid #fff;
  border-radius: 50%;
  position: absolute;
  top: 32vh;
  left: 5%;
  overflow: hidden;
}


.profTopCover{
  position: relative; 
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}


.viewProfPicCont img{
  height: 100%;
  width: 100%;
  object-fit: cover;

}


.profNameCont{
  position: absolute;
  left: 24%; 
  width: 71%;
  padding-top: 15px;

}


.profNameCont h3{
  padding: 0;
  margin: 0;
}


.profNameCont p{
  padding: 0;
  margin: 0;
} 

.profNameCont{
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.btnViewProfPic{
  background-color: royalblue;
  font-size: 13px;
  padding: 10px 25px;
  color: white;
  border: 1px solid royalblue;
  border-radius: 20px;
}

.mapIcon{
  color: #6A6A6A;
}

.profNameCont p{
  color: #6A6A6A; 

}


.profBottomCover{
  width: 100%;
  position: relative; 
  padding-top: 170px; 
  display: flex;
  padding-left: 5%;
  padding-right: 1%;
  padding-bottom: 50px;
}

.profBottomOne{
  width: 25%;
  background-color: #fff;
  height: max-content;
  border-radius: 10px;
  box-shadow: 0 .5rem 1.25rem rgba(115, 82, 199, .18);
  padding: 20px; 
  position: sticky;
  top: 10vh; 
}


.profBottomTwo{
  width: 75%; 
  height: 100%;
  padding-left: 1%;
  padding-right: 1%; 
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow: scroll;
  padding-top: 50px;
}

.profBottomTwo2{
  width: 75%; 
  height: 100%;
  padding-left: 1%;
  padding-right: 1%; 
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow-x: auto; /* Allows horizontal scrolling if there are many items */
  scrollbar-width: none; /* For Firefox */
}

.profBottomTwo2::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Edge */
}

.profileBio{
  font-size: 13px;
  color: #333;
}

.inoutCalls{
  font-size: 13px;
  margin: 0;
  padding: 0;
  /* margin-bottom: 5px;  */
}

.inoutCalls2{
  font-size: 13px;
  margin: 0;
  padding: 0;
  margin-top: 5px;  
}

.serviceTime{
  font-size: 13px; 
  margin: 0;
  padding: 0;
}


.eyeIcon{
  color: #6A6A6A;
  font-size: 13px;
}


.servicePoviderListing2{
  width: 300px;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px; 
  position: relative;
  cursor: pointer;
}

.viewMoreOpacity{
  opacity: 0.3;
  background-color: black;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
}

.viewMoreDiv{
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: 12;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.viewMoreDiv p{
  color: white;
  font-size: 16px;
}

.serviceProvImageCont2{
  width: 100%;  
  height: 300px;
  border-radius: 10px; 
  overflow: hidden;
}

.serviceProvImageCont3{
  width: 100%;  
  height: 300px;
  border-radius: 10px; 
  overflow: hidden;
  position: relative;
}

.serviceProvImageCont3 img{
  object-fit: cover;
  height: 100%;
  width: 100%; 
  object-position: bottom;
}


.serviceProvImageCont2 img{
  object-fit: cover;
  height: 100%;
  width: 100%; 
}

.servicePoviderListing2 P{
  color: #6A6A6A;
  margin: 0;
  padding: 0;
  font-size: 13px;
}

.servicePoviderListing2 h6{
  margin-bottom: 0;
  padding: 0;
  font-size: 14px;
  margin-top: 5px;
  color: #222222;
}

.verifiedBtn{
  color: cornflowerblue;
  margin-left: 10px;
}

.verifiedBtnLive{
  color: cornflowerblue;
  margin-left: 1px;
  font-size: 20px;
}


.chatButton{
  background-color: #FF385C;
  color: white;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 99;
}


.userProfileContactCentre{
  display: flex;
  align-items: center;
}

.verifiedMobileSpan{
  display: none;
}

.userProfileContactCentre div{
  margin-left: 10px;
}



/*  */

.productsSection {
  width: 300px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer; 
}

.productsSection h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

ul {
  list-style-type: none;
  padding: 0;
}

.productItem {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
}

.productItem:hover {
  transform: scale(1.02);
}

.productItem i {
  font-size: 24px;
  color: #5e60ce;
  margin-right: 15px;
}

.productDetails {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.productName {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.productPrice {
  font-size: 14px;
  color: #4caf50;
  font-weight: bold;
}


.fromSpan{
  font-size: 11px;
  color: black; 
  font-weight: normal; 
}




/* signUpContainer */


/* Container for the entire sign-up section */
/* Global styling for smooth animations and modern appearance */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.signUpContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  background-color: #f4f5f7;
  font-family: 'Poppins', sans-serif;
  color: #333;
}

/* Left side (Text and input elements) */
.signUpContainer1 {
  width: 50%;
  padding: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  border-radius: 0 30px 30px 0;
  animation: fadeInLeft 1s ease;
}

.signUpContainer1 h1 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 10px;
  color: #2d2d2d;
  text-transform: capitalize;
  letter-spacing: 1.2px;
}

.signUpContainer1 h3 {
  font-size: 1.8rem;
  margin-bottom: 10px;
  color: #555;
}

.signUpContainer1 h2 {
  font-size: 3rem;
  margin-bottom: 15px;
  color: #ff5a5f;
}

.signUpContainer1 p {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #666;
  text-align: left;
}

/* Slider styling */
.incomeSlider {
  width: 100%;
  margin-bottom: 30px;
  accent-color: #ff5a5f;
  cursor: pointer;
}

/* Button styling */
.ctaButton {
  padding: 15px 35px;
  font-size: 1.3rem;
  color: #fff;
  background-color: #ff5a5f;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 8px 20px rgba(255, 90, 95, 0.3);
}

.ctaButton:hover {
  background-color: #ff787a;
  box-shadow: 0 12px 25px rgba(255, 90, 95, 0.4);
}

/* Right side (Image or illustration) */
.signUpContainer2 {
  width: 50%;
  height: 100%;
  background-image: url('./Images/pexels-gabby-k-6973954.jpg');
  background-size: cover;
  background-position: center;
  border-radius: 30px 0 0 30px;
  animation: fadeInRight 1s ease;
}

/* Logo styling */
.logoSignUp {
  height: 70px;
  margin-bottom: 50px;
  display: block;
}

/* Days text styling */
.spanDays {
  text-decoration: underline;
  font-weight: bold;
  color: #333;
  margin-left: 0px; 
}

/* Fade-in animations */
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translateX(50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
 

.viewMoreServices{
  color: royalblue; 
  cursor: pointer;
  /* text-decoration: underline;  */
}

.topUploadProfilePage{
  position: absolute;
  top: 120px; 
  right: 5%; 
}

.topUploadProfilePage button{
  margin-left: 10px; 

}

.profileAlert{
  margin-left: 20px;
  margin-right: 20px; 
}


/*  */

.video-container {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
}

.video-thumbnail {
  width: 100%;
  height: 100%; 
  object-fit: cover;
}

.play-button-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 50px;
  cursor: pointer;
  opacity: 0.7;
}

.play-button-overlay:hover {
  opacity: 1;
}

.play-icon {
  pointer-events: none; /* Prevent the play button from interfering with video clicks */
}


.delete-icon-profile{
  position: absolute; 
  color: white;
  font-size: 25px; 
  z-index: 99; 
  top: 10px; 
  right: 10px; 
  cursor: pointer;
  padding: 10px;

}


.modal-header{
  display: flex;
  justify-content: space-between;
}


.modal-header button{
  background-color: transparent;
  border: transparent;
}

.modal-dialog{
  margin-top: 0px; 
}

.bottomPageEdit{

  padding-right: 10% !important;
  padding-top: 30px; 
  padding-left: 10%;

}

/* components */
/* Custom CSS to remove background color and arrow */
.custom-dropdown-toggle {
  background-color: transparent !important; /* Remove background color */
  border: none !important;                 /* Remove border */
  box-shadow: none !important;             /* Remove any shadow */
  padding: 0 !important;                   /* Adjust padding */
}

.custom-dropdown-toggle::after {
  display: none !important;                /* Remove default Bootstrap arrow */
}

/* Styling for profile and account container */
.accountCont {
  display: flex;
  align-items: center;
}

/* footer  */

.heardEnough{
  height: 40vh;
  width: 100%;
  background-color: #f0f2f5;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 5%;
  padding-right: 5%; 
}

.getStartediconFooter{
  background-color: #FF385C;
  height: 60px;
  width: 60px; 
  display: flex;
  align-items: center;
  justify-content: center;
  color: white; 
  border-radius: 50%; 
  cursor: pointer;

} 

.heardEnough h1{
  text-decoration: underline #ff787a;
  font-size: 60px;
  margin: 0;
  padding: 0; 
}

.heardEnough h6{
  width: 100px;
  margin: 0;
  padding: 0;
}


.bottomFooter{
  height: auto;
  width: 100%;
  background-color: #222222;
  background-image: url(./Images/BG-3.a47b2fbe.webp);
  /* padding-left: 10%;  
  padding-right: 10%;  */
  color: #fff;
  text-align: left;

  display: flex;
  justify-content: space-around;
  padding: 50px 5%;
}


.footerColumn {
  width: 20%;
}

.footerColumn h2 {
  font-size: 20px;
  margin-bottom: 20px;
  color: #fff;
  font-weight: 700;
}

.footerColumn p, .footerColumn ul {
  margin: 0;
  color: #bababa;
  font-size: 14px; 
}

.QuickLinks{
  color: #bababa; 
  text-decoration: none; 
}

.footerColumn ul {
  list-style: none;
  padding: 0;
}

.footerColumn ul li {
  margin-bottom: 10px;
}

.socialIcons {
  display: flex;
  /* justify-content: space-around; */
  font-size: 24px; 
  margin-top: 20px; 
}


.footertwoP p{
  margin-bottom: 5px;  
}

.thesocialIcons{
  margin-right: 10px;
}

.footerSubscription {
  margin-top: 40px;
}

.footerSubscription h3 {
  margin-bottom: 15px;
  font-size: 24px;
}

.footerSubscription input {
  padding: 10px;
  width: 60%;
  margin-right: 10px;
  border-radius: 5px;
  border: none;
}

.footerSubscription button {
  padding: 10px 20px;
  background-color: #ff5a5f;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.footerCopyright {
  margin-top: 30px;
  font-size: 14px;
  text-align: center;
}



.locationFormInput{
  padding-left: 0px !important; 
}

.locationFormInput:focus, textarea:focus, select:focus {
  outline: none;
  box-shadow: none;
  background-color: transparent;
} 


.package-sub-title{
  text-align: center;
  font-size: 15px;
}



.resultCard{
  border: 1px solid gray;
  width: max-content;
}

.searchResults h3{
  margin-left: 5%;
  margin-bottom: 0;
  padding-bottom: 0;
}

.searchResults h4{
  margin-left: 5%;
  margin-top: 0;
  padding-top: 0;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
  font-size: 1.5rem;
  font-weight: bold;
}
 


.searchBoxMobile{
  border: 1px solid #dddddd; 
  width: 90%; 
  border-radius: 40px;
  overflow: hidden;
  display: none;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.08);
  padding-left: 20px;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 5%; 
  margin-right: 5%;
  cursor: pointer; 
  margin-top: 30px;
  margin-bottom: 10px;
}

.searchBoxMobile h6{
  margin: 0;
  padding: 0;
}

.searchBoxMobile p{
  margin: 0;
  padding: 0; 
  font-size: 12px;
  
}

.searchIconMobileView{
  font-size: 16px; 
  margin-right: 10px;
}

.mobileSearchBoxClose{
  height: 35px;
  width: 35px;
  border: 1px solid #2d2d2d;
  border-radius: 50%;
  margin-bottom: 20px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2d2d2d;
}

.mobileSearchBox{
  position: absolute;
  height: 100vh;
  background-color: white;
  width: 100%;
  top: 0; 
  padding: 20px;

}


.hidden {
  display: none;
}

/* Visible state */
.visible {
  display: block;
} 



/* about */
/* 
.aboutTopBar{
  height: 50vh;
  background-position: top 20%;  
  background-size: cover;
  background-color: #FF385C;
  position: relative; 
}

.funnyOpacityCover{
  background-image: linear-gradient(135deg, transparent 45%, #9c2531 45%, #9c2531 55%, transparent 0);
  background-size: 5px 5px;
  height: 100%;
  width: 100%;
}


.aboutTopBarCont{
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aboutTopBarCont h1{
  font-size: 50px;
  font-weight: bold;
}


.aboutCont{
  display: flex;
  background-color: #f5f5f5; 
  padding-bottom: 50px;
  padding-left: 5%;
  padding-right: 5%; 
} 


.aboutCont img{
  height: 70vh;
} */




.aboutTopBar {
  position: relative;
  background-image: url('./Images/about-main-img.jpg');
  background-size: cover;
  height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
}

.funnyOpacityCover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.aboutTopBarCont {
  z-index: 2;
}

.pageTitle {
  font-size: 3rem;
  color: #fff;
  font-weight: 700;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}

.cta-button {
  padding: 10px 20px;
  background-color: #ff9800;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
}

.about-section, .mission-section, .clients-section, .therapists-section, .why-us-section, .vision-section {
  padding: 80px 0;
  background-color: #f9f9f9;
}

.card {
  border: none;
  padding: 20px;
  text-align: center;
}

.icon-box img {
  width: 50px;
  margin-bottom: 20px;
}

.features-grid {
  display: flex;
  gap: 30px;
}

.list-features {
  list-style: none;
  padding: 0;
}

.list-features li {
  padding: 10px 0;
}

img.img-fluid {
  max-width: 350px;
  height: auto; 
  margin-left: 10%;
}

/* h2, h3, h4 {
  margin-bottom: 20px;
}

p {
  margin-bottom: 15px;
} */

.text-center {
  text-align: center;
}


.img-fluid{
  height: 15vh;
}


.about3647{
  display: flex;
  align-items: center;
  justify-content: center;
}


.help-centre {
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  color: #333;
}

.help-centre h1, h2 {
  color: #444;
}

.help-centre section {
  margin-bottom: 40px;
}

.help-centre h4 {
  color: #555;
  margin-bottom: 10px;
}

.help-centre p {
  line-height: 1.6;
  color: #666;
}

.help-centre ul {
  list-style: none;
  padding: 0;
}

.help-centre ul li {
  margin-bottom: 10px;
}

.help-centre a {
  color: #3498db;
  text-decoration: none;
}

.community-guidelines {
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  color: #333;
}

.community-guidelines h1 {
  color: #444;
  margin-bottom: 30px;
}

.community-guidelines h2 {
  color: #555;
  margin-bottom: 20px;
}

.community-guidelines section {
  margin-bottom: 40px;
}

.community-guidelines ul {
  list-style: disc;
  margin-left: 20px;
}

.community-guidelines ul li {
  margin-bottom: 10px;
}

.community-guidelines p {
  line-height: 1.6;
  color: #666;
}

.community-guidelines a {
  color: #3498db;
  text-decoration: none;
}


.terms-of-service {
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  color: #333;
}

.terms-of-service h1 {
  color: #444;
  margin-bottom: 30px;
}

.terms-of-service h2 {
  color: #555;
  margin-bottom: 20px;
}

.terms-of-service section {
  margin-bottom: 40px;
}

.terms-of-service ul {
  list-style: disc;
  margin-left: 20px;
}

.terms-of-service ul li {
  margin-bottom: 10px;
}

.terms-of-service p {
  line-height: 1.6;
  color: #666;
}

.terms-of-service a {
  color: #3498db;
  text-decoration: none;
}

.privacy-policy {
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  color: #333;
}

.privacy-policy h1 {
  color: #444;
  margin-bottom: 30px;
}

.privacy-policy h2 {
  color: #555;
  margin-bottom: 20px;
}

.privacy-policy section {
  margin-bottom: 40px;
}

.privacy-policy ul {
  list-style: disc;
  margin-left: 20px;
}

.privacy-policy ul li {
  margin-bottom: 10px;
}

.privacy-policy p {
  line-height: 1.6;
  color: #666;
}

.privacy-policy a {
  color: #3498db;
  text-decoration: none;
}
 
.faq {
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  color: #333;
}

.faq h1 {
  font-size: 36px;
  margin-bottom: 30px;
  text-align: center;
  color: #444;
}

.faq h2 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #555;
}

.faq-item {
  margin-bottom: 30px;
}

.faq-item h3 {
  font-size: 22px;
  margin-bottom: 10px;
  color: #3498db;
}

.faq-item p {
  line-height: 1.6;
  color: #666;
}

.faq ul {
  list-style: disc;
  margin-left: 20px;
}
.safety-page {
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  color: #333;
}

.safety-page h1 {
  font-size: 36px;
  margin-bottom: 30px;
  text-align: center;
  color: #444;
}

.safety-section {
  margin-bottom: 40px;
}

.safety-section h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #3498db;
}

.safety-section p {
  line-height: 1.6;
  color: #666;
}

.safety-section ul {
  list-style: disc;
  margin-left: 20px;
  color: #666;
}

.safety-section a {
  color: #3498db;
  text-decoration: none;
}

.safety-section a:hover {
  text-decoration: underline;
}


.refund-policy {
  padding: 40px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  color: #333;
}

.refund-policy h1 {
  font-size: 36px;
  margin-bottom: 30px;
  text-align: center;
  color: #444;
}

.refund-section {
  margin-bottom: 40px;
}

.refund-section h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #3498db;
}

.refund-section p {
  line-height: 1.6;
  color: #666;
}

.refund-section ul {
  list-style: disc;
  margin-left: 20px;
  color: #666;
}

.refund-section a {
  color: #3498db;
  text-decoration: none;
}

.refund-section a:hover {
  text-decoration: underline;
}

/* pricing */


.pricing-plan {
  max-width: 100%;
  margin: 0 auto;
  padding: 40px;
  text-align: left;
  background-color: #F9F9F9;
  margin-top: 20px;
}

.pricing-title {
  font-size: 36px;
  margin-bottom: 10px;
  color: #444;
  text-align: center;
}

.pricing-description {
  font-size: 18px;
  margin-bottom: 40px;
  color: #666;
  text-align: center;
}

/* Toggle Switch for Billing Cycle */
.billing-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.billing-toggle span {
  font-size: 16px;
  margin: 0 10px;
  cursor: pointer;
  color: #666;
}

.billing-toggle .active {
  font-weight: bold;
  color: #3498db;
}

.toggle-switch {
  width: 50px;
  height: 24px;
  background-color: #ddd;
  border-radius: 50px;
  position: relative;
  cursor: pointer;
  margin: 0 10px;
}

.toggle-circle {
  width: 20px;
  height: 20px;
  background-color: #3498db;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  transition: 0.3s;
}

.toggle-circle.monthly {
  left: 2px;
}

.toggle-circle.yearly {
  left: 28px;
}

.pricing-packages {
  display: flex;
  justify-content: space-between;
  gap: 20px;

}


.pricing-package {
  margin-bottom: 2rem;
  padding: 1rem;
  border: 1px solid #ddd;
  width: 33%;
}

.package-title {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;

}

.package-price {
  font-size: 1.25rem;
  margin-bottom: 1rem;
  color: #FF385C;
  text-align: center;
}

.package-features {
  list-style-type: none;
  padding: 0;
}

.feature-item {
  margin-bottom: 0.5rem;
  display: flex;
  align-items: start;
  justify-content: start;
}

.upgradecheck {
  margin-right: 0.5rem;
  color: green;
}

.select-button {
  background-color: #FF385C; 
  color: white;
  width: 100%;
  padding: 0.75rem;
  border: none;
  font-size: 1rem;
  cursor: pointer;
  margin-top: auto;
  display: block;
  border-radius: 5px;
}


/* PaymentPage.css */
/* .payment-container {
  max-width: 500px;
  margin: 50px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  font-family: 'Arial', sans-serif;
}

.payment-header h1 {
  font-size: 24px;
  color: #333;
  text-align: center;
}

.payment-header p {
  text-align: center;
  font-size: 14px;
  color: #666;
}

.payment-options {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.payment-option {
  padding: 15px 20px;
  background-color: #f5f5f5;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  color: #333;
}

.payment-option:hover {
  background-color: #ddd;
}

.payment-option.selected {
  background-color: #4CAF50;
  color: white;
}

.payment-card, .payment-mpesa {
  margin-top: 20px;
}

.payment-card h2, .payment-mpesa h2 {
  font-size: 20px;
  color: #333;
  margin-bottom: 15px;
}

.card-details label, .payment-mpesa label {
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
  display: block;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.small-input {
  width: 45%;
  display: inline-block;
  margin-right: 10%;
}

.submit-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 100%;
  text-align: center;
}

.submit-button:hover {
  background-color: #45a049;
}
  */


  .payment-container {
    background: #f9f9f9;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 0 auto;
    position: relative;
}

.order-summary-container {
    background: white;
    padding: 1.5rem;
    border-radius: 10px;
    margin-bottom: 2rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.order-summary-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 1rem;
}

.order-summary-details {
    display: flex;
    flex-direction: column;
}

.order-item {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 0;
}

.item-label {
    font-weight: bold;
    color: #777;
}

.item-value {
    font-weight: 600;
    color: #333;
}

.payment-header {
    text-align: center;
    margin-bottom: 2rem;
}

.payment-title {
    font-size: 1.8rem;
    font-weight: bold;
    color: #222;
}

.payment-description {
    font-size: 1rem;
    color: #555;
    margin-top: 0.5rem;
}

.payment-options {
    display: flex;
    justify-content: space-around;
    margin-bottom: 2rem;
}

.payment-option {
    background: #f0f0f0;
    padding: 1rem;
    border: none;
    border-radius: 8px;
    font-size: 1.1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background 0.3s ease;
}

.payment-option.selected {
    background: #d1e7dd;
    border: 2px solid #28a745
}


.dismiss-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  color: #999;
  cursor: pointer;
  transition: color 0.2s;
}

.dismiss-button:hover {
  color: #555;
}

.hidden {
  display: none;
}


.getverifiedSpan{
  font-size: 13px;
  background-color: royalblue;
  padding: 4px 20px;
  color: white;
  border-radius: 20px;
  margin-left: 10px;
  z-index: 4;
  cursor: pointer;
  position: relative;
}

.verifiedMobileSpan2{
display: none;
}



/* CSS for the No Results section */
.no-results {
  text-align: center;
  margin: 50px auto;
  padding: 30px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  max-width: 600px;
}

.no-results h4 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
}

.no-results p {
  font-size: 18px;
  color: #666;
  margin-bottom: 20px;
}

.sign-up-btn {
  display: inline-block;
  padding: 12px 25px;
  background-color: #ff6b6b;
  color: white;
  font-size: 16px;
  font-weight: 600;
  border-radius: 30px;
  text-decoration: none;
  transition: background-color 0.3s ease;
  box-shadow: 0 5px 15px rgba(255, 107, 107, 0.3);
}

.sign-up-btn:hover {
  background-color: #ff5252;
  box-shadow: 0 8px 20px rgba(255, 82, 82, 0.4);
}



/* Responsive Design */
@media (max-width: 768px) {
  
  .no-results {
    padding: 20px;
    margin: 30px auto;
  }

  .no-results h4 {
    font-size: 20px;
  }

  .no-results p {
    font-size: 16px;
  }

  .sign-up-btn {
    padding: 10px 20px;
    font-size: 14px;
  }
}


/* video call section */

.usersLiveNow{
  padding-left: 5%;
  margin-top: 20px;
  margin-bottom: 40px;
}

.servicePoviderListing{
  width: 300px !important;
  margin: 10px; 
}

.studentCard {
  position: relative;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding-bottom: 5px;
}

.studentCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

.studentImageContainer img {
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
  object-position: 50% 20%;
}

.liveIconBlinking {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 20px;
  height: 20px;
  border: 2px solid white;
  border-radius: 50%;
  background-color: red;
  animation: blink 1.5s infinite;
}

@keyframes blink {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}

.videoCallButton {
  transform: translateX(-50%);
  background-color: #fff;
  color: #FF385C;
  border-radius: 5px;
  font-weight:normal;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
  z-index: 33;
  padding: 8px 12px;
}

.videoCallLink{
  text-decoration: none;
  color: #FF385C;
  padding: 8px 12px;
  position: absolute; 
  bottom: 70px; 
  left: 50%; 
}

.videoCallLink:hover{
  color: white;
}

.videoCallButton:hover {
  background-color: #FF385C;
  color: white;
}

.verifiedIcon {
  color: green;
  margin-left: 5px;
}
 
.studentLink p{
  margin-left: 10px;
}


.studentLink h6{
  margin-left: 10px;
}


/* start a video call*/

.startAcall{
  height: 100vh;
  width: 100%;
  background-image: url(./Images/sampletwo.webp);
  background-size: cover; 
  position: relative;
}


.startAcallOpacity{
  height: 100%;
  background-color: black;
  opacity: 0.85;
  width: 100%;
  position: absolute;
  top: 0;
} 

.startAcallContent{
  position: absolute;
  top: 0;
  z-index: 33;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.startAcallContentDiv{
  height: max-content;
  width: 40%;
  background-color: white;
  border-radius: 5px;
  position: relative;
}


.chatButtonVideo{
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  
}


.spanoffline {
  background-color: gray;
  font-size: 13px;
  border-radius: 20px;
  padding: 1px 4px 2px 4px;
  color: white; /* Ensure contrast for readability */
  margin-left: 5px;
}

.spanonline {
  background-color: green;
  font-size: 13px;
  color: white;
  border-radius: 20px;
  padding: 1px 4px 2px 4px;
  margin-left: 5px;
}

.spanoncall {
  background-color: royalblue;
  font-size: 13px;
  color: white;
  border-radius: 20px;
  padding: 1px 4px 2px 4px;
  margin-left: 5px;
}

.text-align-left {
  text-align: left;
}